import React from "react"
import styled from "styled-components"
import Hero, {
  CarouselItem,
  TanghoButton,
  Text,
} from "../components/Layout/Hero"
import SEO from "../components/SEO"
import bgImage from "../../static/img/videoBg.png"
import { navigate } from "gatsby"

const NotFound = () => {
  const Container = styled.div`
    padding: 32px 16px 32px 16px;
    background: ${({
      theme: {
        main: { offWhite },
      },
    }) => offWhite};
  `

  const button: TanghoButton = {
    label: "Return to Home",
    onClick: () => navigate("/"),
    mobileFontSize: "18px",
    desktopFontSize: "24px",
  }

  const heading: Text = {
    text: "Page Not Found",
    mobileMargin: "170px 0 0 0",
    desktopMargin: "350px 0 0 0",
    fontColor: "#FCFBFB",
    mobileFontAlign: "center",
    desktopFontAlign: "center",
    desktopFontSize: "96px",
  }

  const carouselItem: CarouselItem[] = [
    {
      backgroundImg: bgImage,
      btnList: [button],
      heading,
    },
  ]

  return (
    <div>
      <SEO
        title="Tangho - Page Not Found"
        description="The page you requested does not exist."
        image={bgImage}
        url="/404"
      />
      <Hero carouselItems={carouselItem} is404={true} mobileHeight={375} />
    </div>
  )
}

export default NotFound
